<template>
  <Header />
    <main>
      <div class="cont">
        <BackButton/>
        <h1>ヘルプ</h1>
        <section class="app">
          <h2>アプリについて</h2>
          <div class="note icon">
            <h3>一覧画面アイコンの意味</h3>
            施設利用者のアイコン表示には「通常・睡眠・異常・オフライン」があります。
            <dl>
              <dt><img src="@/assets/img/list-icon-nomal.png" alt="通常" /></dt>
              <dd>
                <em>通常</em>:室内でTV鑑賞や読書など通常の活動を行っている状態です。
              </dd>
              <dt><img src="@/assets/img/list-icon-sleep.png" alt="睡眠" /></dt>
              <dd>
                <em>眠り</em>:室内で就寝中の状態です。対象が就寝中の場合は呼吸のリズムが検知できます。
              </dd>
              <dt><img src="@/assets/img/list-icon-emergency.png" alt="不在" /></dt>
              <dd>
                <em>不在</em>:室内に居ないか、室内で身体の動作と呼吸が検知できない状態です。
              </dd>
              <dt><img src="@/assets/img/list-icon-nowave.png" alt="オフライン" /></dt>
              <dd>
                <em>オフライン</em>:機器からの信号が検出されていない状態です。
              </dd>
            </dl>
          </div>
          <div class="note">
            <h3>モニタリングモードでのアイコン表示について</h3>
            <p>アイコンが各利用者の現状態を反映する際に15分程度のタイムラグが発生する場合がございます。</p>
          </div><div class="note">
            <h3>推奨環境</h3>
            <p>Google ChromeやMicrosoft Edge、Mozilla Firefox、Safariなどモダンブラウザの最新バージョンでご利用ください。</p>
          </div>
          <div class="note">
            <h3>推奨モニターサイズについて</h3>
            <p>PCで操作する場合はXGA	1024×768以上の解像度でご利用下さい。</p>
          </div>
          <div class="note">
            <h3>睡眠評価について</h3>
            <p>眠りの評価は連続した深い眠り・浅い眠りを一つの睡眠として計測します。より長く連続している睡眠が好評価となります。</p>
          </div>
          <div class="note">
            <h3>活動状況の取得について</h3>
            <p>リアルタイムの活動状況監視は1回の監視につき60秒、1日の監視合計時間は300秒までです。1日の合計時間が300秒を超えるとリアルタイムの監視は1回あたり3秒に制限されます。</p>
          </div>
          <div class="note">
            <h3>モニタリング対象の削除について</h3>
            <p>モニタリング対象を削除する必要が出た場合はメールにてご依頼下さい。<br>
            ご依頼先：wi-fi_sensing@securelink-inc.co.jp</p>
          </div>
          <div class="note">
            <h3>法人名の変更について</h3>
            <p>登録された法人名を変更する場合はメールにてご依頼下さい。<br>
            ご依頼先：wi-fi_sensing@securelink-inc.co.jp</p>
          </div>
        </section>
        <section class="hard">
          <h2>機器について</h2>
          <div class="note">
            <h3>オフラインになった場合</h3>
            <p>電源アダプターを本体から掘り外し、5秒後に再度電源アダプターを接続してください。<br>復旧しない場合は<a href="/inquiry">サポートまでお問い合わせ</a>下さい。</p>
          </div>
          <div class="note">
            <h3>機器の設置場所について</h3>
            <p>CareSenceはWi-Fiの電波を使用するので金属板で囲まれた場所は避けて下さい。<br>睡眠時の呼吸のリズムを計測するためには頭側と足側に対になるように機器を設置して下さい。</p>
          </div>
          <div class="note">
            <h3>ご注意</h3>
            <p>CareSenseは医療機器ではありませんので、表記されている値やグラフは推定値を含み正確なものではありません</p>
          </div>
        </section>
      </div>
  </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'Help',
  components: {
    Header,
    Footer
  },
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .cont h2 {
    font-size: 4.4vw;
    padding-bottom: .5em;
  }
  .cont section .note {
    font-size: 3.8vw;
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: rgb(140, 155, 180) dashed 1px;
  }
  .cont section .note h3 {
    font-size: 4.2vw;
  }
  .cont .app .note.icon dl {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .cont .app .note.icon dl dt {
    width: 20%;
    margin-top: 4vw;
  }
  .cont .app .note.icon dl dd {
    width: 78%;
    padding: 4vw 0 0;
  }
  .cont .app .note.icon dl dd em {
    font-style: normal;
    font-weight: 600;
  }
}
@media all and (min-width: 480px) {
  .cont h2 {
    font-size: 1.4vw;
    padding-bottom: 1em;
  }
  .cont section .note {
    font-size: 1vw;
    margin-bottom: 2em;
    padding-bottom: 2em;
  }
  .cont section .note h3 {
    font-size: 1.2vw;
  }
  .cont .app .note.icon dl {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .cont .app .note.icon dl dt {
    width: 6%;
    margin-top: 1vw;
  }
  .cont .app .note.icon dl dd {
    width: 93%;
    padding: 2em 0 0;
  }
  .cont .app .note.icon dl dd em {
    font-style: normal;
    font-weight: 600;
  }
}
</style>
