<template>
  <Header />
  <main class="SelectFacility">
    <div class="cont">
      <BackButton/>
      <h1>施設選択</h1>
      <section>
        <div class="form-item company">
          <span>法人を選択してください</span>
          <ul>
            <li v-for="company in companies" :key="company.id" v-on:click="selectCompany(company.id)"><span>{{ company.name }}</span></li>
          </ul>
        </div>
        <div class="select-id facility">
          <div class="piece" v-for="facility in facilities" :key="facility.id">
            <ul class="note">
              <li class="fname">
                <span class="linktxt" v-on:click="selectFacility(facility.id)">{{ facility.name }}</span>
              </li>
              <li class="ftel">
                <span>{{ facility.tel }}</span>
              </li>
              <li class="fbedcount">
                <span>{{ facility.bed_count }}台</span>
              </li>
              <li class="fmanager">
                <span>{{ facility.manager }}</span>
              </li>
              <li class="fpic">
                <span>{{ facility.person_in_charge }}</span>
              </li>
            </ul>
            <ul>
              <li class="fadd">
                <span>{{ facility.address }}</span>
              </li>
            </ul>

            <div class="monitor" v-if="typeof(facility.summary) !== 'undefined'">
              <span>不在（{{ facility.summary.alert }}）</span>
              <span>普通状態（{{ facility.summary.active }}）</span>
              <span>就寝中（{{ facility.summary.sleeping }}）</span>
              <span>オフライン（{{ facility.summary.offline }}）</span>
            </div>

          </div>
        </div>
      </section>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet } from '../../api'
import { targetUserStatus } from '../../constants'

export default {
  name: 'SelectFacility',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      companies: [],
      selectedCompanyId: null,
      facilities: [],
      summaryOfFacility: {}
    }
  },
  mounted() {
    const loadCompany = async () => {
      try {
        const response = await apiGet('/api/company', this.$router.app.$cookies)
        this.companies = response.data
        if (this.companies.length > 0) {
          this.selectedCompanyId = this.companies[0].id
        }
      } catch (error) {
        console.log(error)
      }
    }
    loadCompany()
  },
  watch: {
    selectedCompanyId: async function(newVal) {
      const response = await apiGet(`/api/facility?company_id=${newVal}`, this.$router.app.$cookies)
      this.facilities = response.data
      this.facilities.forEach(facility => {
        this.loadSummaryOfFacility(facility.id)
      })
    }
  },
  methods: {
    selectCompany: function(companyId) {
      this.selectedCompanyId = companyId
    },
    selectFacility: function(facilityId) {
      this.$router.push(`/facility_manager?id=${facilityId}`)
    },
    loadSummaryOfFacility: async function(facilityId) {
      const response = await apiGet(`/api/target_user/search?facility_id=${facilityId}`, this.$router.app.$cookies)
      const targetUsers = response.data

      const summary = {
        'active': 0,
        'alert': 0,
        'sleeping': 0,
        'offline': 0
      }
      targetUsers.forEach(targetUser => {
        if (targetUser.status === targetUserStatus.active) {
          summary['active'] += 1
        } else if (targetUser.status === targetUserStatus.inactive) {
          summary['alert'] += 1
        } else if (targetUser.status === targetUserStatus.sleeping) {
          summary['sleeping'] += 1
        } else if (targetUser.status === targetUserStatus.offline) {
          summary['offline'] += 1
        }
      })

      this.facilities.forEach(facility => {
        if (facility.id === facilityId) {
          facility.summary = summary
        }
      })
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .SelectFacility section {
    font-size: 3.8vw;
  }
  .SelectFacility section .piece {
    padding-bottom: 2em;
  }
  .SelectFacility section .form-item {
    font-size: 3.8vw;
    padding: 0 0 1em;
  }
  .SelectFacility section .form-item.company ul {
    padding: 1em 0;
  }
  .SelectFacility section .form-item.company ul li {
    padding: 0 0 .5em;
  }
  .SelectFacility section .form-item.company ul li span {
    display: block;
    padding: .5em;
    border: rgb(70, 150, 255) solid 1px;
    border-radius: .7em;
    background: rgb(220, 240, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .SelectFacility section .form-item.company ul li span:hover {
    background: rgb(175, 220, 255);
  }
  .SelectFacility section .select-id.facility {
  }
  .SelectFacility section .select-id.facility ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: rgb(255,255,255);
    width: 100%;
    border-bottom: rgb(200,200,200) solid 1px;
    border-left: rgb(200,200,200) solid 1px;
  }
  .SelectFacility section .select-id.facility ul.note {
    border-top: rgb(200,200,200) solid 1px;
    border-top-left-radius: 3vw;
    border-top-right-radius: 3vw;
  }
  .SelectFacility section .select-id.facility ul li {
    border-right: rgb(200,200,200) solid 1px;
  }
  .SelectFacility section .select-id.facility ul li.fname {
    width: 100%;
    border-bottom: rgb(200,200,200) solid 1px;
    border-top-left-radius: 3vw;
    border-top-right-radius: 3vw;
  }
  .SelectFacility section .select-id.facility ul li.ftel {
    width: 50%;
    border-bottom: rgb(200,200,200) solid 1px;
  }
  .SelectFacility section .select-id.facility ul li.fbedcount {
    width: 50%;
    border-bottom: rgb(200,200,200) solid 1px;
  }
  .SelectFacility section .select-id.facility ul li.fmanager {
    width: 50%;
  }
  .SelectFacility section .select-id.facility ul li.fpic {
    width: 50%;
  }
  .SelectFacility section .select-id.facility ul li span {
    display: block;
    padding: .5em 0;
    text-align: center;
  }
  .SelectFacility section .select-id.facility ul li.fadd {
    width: 100%;
  }
  .SelectFacility section .select-id.facility ul li.fadd span {
    display: block;
    padding: .5em;
    text-align: left;
  }
  .SelectFacility section .select-id.facility .monitor {
    padding: .5em;
    border: rgb(200,200,200) solid 1px;
    border-top: none;
    border-bottom-left-radius: 1.5vw;
    border-bottom-right-radius: 1.5vw;
    background: rgb(255,255,255);
    text-align: center;
  }
  .SelectFacility section .select-id.facility .monitor span {
    font-size: 3.4vw;
    display: inline-block;
    padding: 0;
  }
}
@media all and (min-width: 480px) {
  .SelectFacility section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 1.4vw;
  }
  .SelectFacility section .piece {
    padding-bottom: 2em;
  }
  .SelectFacility section .form-item {
    font-size: 1.4vw;
    padding: 0 50px 1em 0;
    width: 25%;
  }
  .SelectFacility section .form-item.company ul {
    padding: 1em 0;
  }
  .SelectFacility section .form-item.company ul li {
    padding: 0 0 .5em;
  }
  .SelectFacility section .form-item.company ul li span {
    display: block;
    padding: .5em;
    border: rgb(70, 150, 255) solid 1px;
    border-radius: .7em;
    background: rgb(220, 240, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .SelectFacility section .form-item.company ul li span:hover {
    background: rgb(175, 220, 255);
  }
  .SelectFacility section .select-id.facility {
    width: 74%;
  }
  .SelectFacility section .select-id.facility ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: rgb(255,255,255);
    width: 100%;
    border-bottom: rgb(200,200,200) solid 1px;
    border-left: rgb(200,200,200) solid 1px;
  }
  .SelectFacility section .select-id.facility ul.note {
    border-top: rgb(200,200,200) solid 1px;
    border-right: rgb(200,200,200) solid 1px;
    border-top-left-radius: 1.5vw;
    border-top-right-radius: 1.5vw;
  }
  .SelectFacility section .select-id.facility ul li {
    border-right: rgb(200,200,200) solid 1px;
  }
  .SelectFacility section .select-id.facility ul.note li:last-child {
    border-right: none;
  }
  .SelectFacility section .select-id.facility ul li.fname {
    width: 40%;
  }
  .SelectFacility section .select-id.facility ul li.ftel {
    width: 20%;
  }
  .SelectFacility section .select-id.facility ul li.fbedcount {
    width: 10%;
  }
  .SelectFacility section .select-id.facility ul li.fmanager {
    width: 15%;
  }
  .SelectFacility section .select-id.facility ul li.fpic {
    width: 15%;
  }
  .SelectFacility section .select-id.facility ul li span {
    display: block;
    padding: .5em 0;
    text-align: center;
  }
  .SelectFacility section .select-id.facility ul li.fadd {
    width: 100%;
  }
  .SelectFacility section .select-id.facility ul li.fadd span {
    display: block;
    padding: .5em;
    text-align: left;
  }
  .SelectFacility section .select-id.facility .monitor {
    padding: .5em;
    border: rgb(200,200,200) solid 1px;
    border-top: none;
    border-bottom-left-radius: 1.5vw;
    border-bottom-right-radius: 1.5vw;
    background: rgb(255,255,255);
    text-align: center;
  }
  .SelectFacility section .select-id.facility .monitor span {
    display: inline-block;
    padding: 0 .5em;
  }
}
</style>
