<template>
  <Header />
  <main>
    <div class="cont">
      <template v-if="facility">
        <h1>{{ facility.name }}</h1>
        <div class="info-notes">
          <p><span v-if="facility.address">{{ facility.address }}</span><span v-if="facility.tel">（{{ facility.tel }}）</span></p>
          <p>
            <span v-if="facility.bed_count">（ベッド数：{{ facility.bed_count }}台）</span>
            <span v-if="facility.manager">施設長：{{ facility.manager }}</span>
            <span v-if="facility.person_in_charge">担当：{{ facility.person_in_charge }}</span>
          </p>
        </div>
      </template>

      <h2>{{ targetUser.room_name }} / {{ targetUser.name }}さんの詳細</h2>

      <div class="btns w100">
        <button v-on:click="showFacilityManager">ダッシュボード</button>
        <button v-on:click="showTimeline">タイムライン</button>
        <button v-on:click="showFacilityConfigMenu">設定</button>
        <p>
          <span>S/N番号</span>
          <span v-for="deviceId in targetUser.device_id_list" :key="deviceId">{{ deviceId }}</span>
        </p>
      </div>

      <div class="chart">
        <div class="live">
          <div class="live-btn" v-if="!getRealTimeDataFlag" v-on:click="getRealTimeDataStart"><button>リアルタイムデータを確認する</button></div>
          <div class="chart-container">
            <h2><span>リアルタイムデータ</span></h2>
            <div class="btns">
              <label v-on:click="showActivityAndBreathing"><input type="radio" id="act-breath1" name="act-breath" value="act-breath" checked />室内の活動/呼吸のリズム</label>
              <label v-on:click="showActivity"><input type="radio" id="act-breath2" name="act-breath" value="act" />室内の活動</label>
              <label v-on:click="showBreathing"><input type="radio" id="act-breath3" name="act-breath" value="breath" />呼吸のリズム</label>
            </div>
            <div class="average-value">
              <p v-if="startTime !== null">{{ startTime.toLocaleTimeString() }}より計測開始</p>
              <p v-if="averageBreathingString && isVisibleBreathing" class="averagebreath">{{ averageBreathingString }}</p>
            </div>
            <ActivityChart v-if="getRealTimeDataFlag" :data="activityChartData" :startTime="startTime"/>
          </div>
        </div>
      </div>

      <div class="note">
        <h3>メモ</h3>

        <ul ref="note">
          <li v-for="memo in memos" :key="memo.id">
            <p><span class="date">{{ formatTime(memo.created_at) }}</span><em class="describer">{{ memo.writer_name }}</em></p>
            <p class="memo">{{ memo.body }}</p>
          </li>
        </ul>
        <div class="memo-ipt">
          <p>{{ today.toLocaleDateString('ja-JP') }}</p>
          <p><input type="text" v-model="newMemoBody" placeholder="本文（必須）"></p>
          <p>
            <select v-model="newMemoWriter">
              <option value="">担当者を選択</option>
              <option v-for="facilityAdminUser in facilityAdminUsers" :key="facilityAdminUser.id" :value="facilityAdminUser.name">{{ facilityAdminUser.name }}</option>
            </select>
            <button v-on:click="sendMemo">メモを保存</button>
          </p>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../../Header'
import Footer from '../../Footer'
import { apiGet, apiPost } from '../../../api'
import ActivityChart from '../ActivityChart'

export default {
  name: 'TargetUserDetailRealTime',
  components: {
    Header,
    Footer,
    ActivityChart,
  },
  data () {
    return {
      facility: null,
      targetUser: {
        name: "",
      },
      facilityId: this.$route.query.facility_id,
      targetUserId: this.$route.query.target_user_id,
      memos: [],
      facilityAdminUsers: [],
      newMemoBody: '',
      newMemoWriter: '',
      activityChartData: {
        datasets: []
      },
      breathingChartData: {
        datasets: []
      },
      averageBreathingString: '0.00回/分',
      today: new Date(),
      startTime: null,
      realTimeDataRestTimeString: '',
      isLoading: false,
      getRealTimeDataFlag: false,
      realTimeDataCount: 0,
      isVisibleActivity: true,
      isVisibleBreathing: true,
    }
  },
  mounted() {
    this.getRealTimeDataFlag = false
    this.realTimeDataCount = 0
    this.loadFacility()
    this.loadTargetUser()
    this.loadMemo()
    this.loadFacilityAdminUsers()
  },
  methods: {
    loadFacility: async function() {
      try {
        const response = await apiGet(`/api/facility?id=${this.facilityId}`, this.$router.app.$cookies)
        this.facility = response.data
      } catch (error) {
        console.error(error)
      }
    },
    loadTargetUser: async function() {
      const response = await apiGet(`/api/target_user?target_user_id=${this.targetUserId}`, this.$router.app.$cookies)
      this.targetUser = response.data
    },
    loadFacilityAdminUsers: async function() {
      const response = await apiGet(`/api/user_facility_relation?facility_id=${this.facilityId}`, this.$router.app.$cookies)
      this.facilityAdminUsers = response.data
    },
    formatTime: function(datestr) {
      const unixtime = Date.parse(datestr)
      const date = new Date(unixtime)
      return date.toLocaleString('ja-JP')
    },
    showSleepCondition: function() {
      window.open(`/sleep_condition?id=${this.targetUserId}`)
    },
    sendMemo: async function() {
      try {
        if (this.newMemoBody === '' || this.newMemoWriter === '') {
          alert('本文と記入者の両方を記入してください')
          return
        }
        const response = await apiPost('/api/target_user/memo', {
          target_user_id: this.targetUserId,
          body: this.newMemoBody,
          writer_name: this.newMemoWriter
        }, this.$router.app.$cookies)

        if (response.status === 200) {
          this.newMemoBody = ''
          this.newMemoWriter = ''
          this.loadMemo()
        }
      } catch (error) {
        console.error(error.value)
      }
    },
    loadMemo: async function() {
      try {
        const response = await apiGet(`/api/target_user/memo?target_user_id=${this.targetUserId}`, this.$router.app.$cookies)
        this.memos = response.data
        const memoElement = this.$refs.note
        memoElement.scrollTop = 0
      } catch (error) {
        console.error(error.value)
      }
    },
    startFetchingRealtimeSensorData: async function() {
      this.isLoading = true
      const _this = this

      const heartbeatTimeInterval = 5000
      const heartbeatCountMax = 12
      let heartbeatCount = 1
      this.heartbeatRealtimeSensorData()
      const heartbeatIntervalId = setInterval(function() {
        if (_this.targetUser === null || heartbeatCount > heartbeatCountMax) {
          clearInterval(heartbeatIntervalId)
          return
        }
        _this.heartbeatRealtimeSensorData()
        heartbeatCount += 1
      }, heartbeatTimeInterval)

      const loadRealtimeDataTimeIntarval = 1000
      this.loadRealtimeSensorData()
      const intervalId = setInterval(function() {
        if (_this.targetUser === null) {
          clearInterval(intervalId)
          _this.isLoading = false
          return
        }
        _this.loadRealtimeSensorData()
      }, loadRealtimeDataTimeIntarval)
    },
    heartbeatRealtimeSensorData: async function() {
      try {
        const response = await apiPost('/api/rpm_sensor_data/heartbeat',{
          target_user_id: this.targetUserId
        }, this.$router.app.$cookies)
        this.realTimeDataCount = response.data.todays_count
      } catch (error) {
        console.error(error.value)
      }
    },
    loadRealtimeSensorData: async function() {
      try {
        const response = await apiGet(`/api/rpm_sensor_data?target_user_id=${this.targetUserId}`, this.$router.app.$cookies)

        const activityLineData = []
        var maxStatisticsData = {}
        for (let i = 0; i < response.data.length; i++) {
          const current = response.data[i]
          if (Object.keys(maxStatisticsData).length === 0) {
            maxStatisticsData = {
              x: new Date(current.timestamp * 1000),
              y: Math.max(0, current.statistics),
              timestamp: current.timestamp
            }
          }

          if (current.timestamp === maxStatisticsData.timestamp) {
            if (current.statistics > maxStatisticsData.y) {
              maxStatisticsData = {
                x: new Date(current.timestamp * 1000),
                y: Math.max(0, current.statistics),
                timestamp: current.timestamp
              }
            }
          } else {
            activityLineData.push(maxStatisticsData)
            maxStatisticsData = {
              x: new Date(current.timestamp * 1000),
              y: Math.max(0, current.statistics),
              timestamp: current.timestamp
            }
          }

          if (i === response.data.length-1) {
            activityLineData.push(maxStatisticsData)
          }
        }

        const breathingLineData = response.data.map(data => {
          return {
            x: new Date(data.timestamp * 1000),
            y: data.rate / 100
          }
        })

        this.breathingChartData = {
          datasets: [
            {
              type: 'line',
              data: breathingLineData,
            },
          ]
        }

        let breathingCount = 0
        let breathingTotal = 0
        breathingLineData.forEach((data) => {
          if (data.x >= this.startTime && data.y > 0) {
            breathingCount += 1
            breathingTotal += data.y
          }
        })
        if (breathingCount > 0) {
          this.averageBreathingString = `${(breathingTotal/breathingCount).toFixed(2)}回/分`
        } else {
          this.averageBreathingString = '0.00回/分'
        }

        if (this.realTimeDataCount) {
          this.realTimeDataRestTimeString = `残り${Math.max(Math.floor((600 - this.realTimeDataCount) / 2), 0)}秒`
        }

        const datasets = []
        if (this.isVisibleActivity) {
          datasets.push( {
              type: 'line',
              data: activityLineData,
              borderColor: '#3cb371',
              backgroundColor: 'rgba(60, 179, 113, 0.2)',
              elements: {
                point:{
                  radius: 0
                },
              },
              showLine: true,
              fill: true,
              tension: 0.2,
              yAxisID: 'y_activity',
            })
        }
        if (this.isVisibleBreathing) {
          datasets.push(  {
              type: 'line',
              data: breathingLineData,
              borderColor: '#8a2be2',
              backgroundColor: 'rgba(138, 43, 226, 0.7)',
              elements: {
                point:{
                  radius: 2
                },
              },
              showLine: false,
              yAxisID: 'y_breathing',
            })
        }
        this.activityChartData = {
          datasets: datasets
        }
      } catch (error) {
        console.error(error)
      }

      this.isLoading = false
    },
    getRealTimeDataStart: function() {
      this.getRealTimeDataFlag = true
      this.startTime = new Date()
      this.startFetchingRealtimeSensorData()
    },
    showFacilityManager: function() {
      this.$router.push(`/facility_manager?id=${this.facilityId}`)
    },
    showTimeline: function() {
      this.$router.push(`/target_user_detail/timeline?facility_id=${this.facilityId}&target_user_id=${this.targetUserId}`)
    },
    showFacilityConfigMenu: function() {
      this.$router.push(`/facility_config_menu?id=${this.facilityId}`)
    },
    showActivityAndBreathing: function() {
      this.isVisibleActivity = true
      this.isVisibleBreathing = true
    },
    showActivity: function() {
      this.isVisibleActivity = true
      this.isVisibleBreathing = false
    },
    showBreathing: function() {
      this.isVisibleActivity = false
      this.isVisibleBreathing = true
    },
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .chart .live {
    position: relative;
  }
  .chart .live .live-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .8);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .chart-container {
    padding: 1em 1em;
    position: relative;
  }
  .velmld-parent {
    position: fixed!important;
  }
  .info-notes {
    padding-bottom: 4vw;
  }
  .info-notes p {
    font-size: 3.4vw;
  }
  .cont h2 {
    font-size: 4vw;
    padding: 1.5em 0;
  }
  .btns {
    display: flex;
    flex-flow: row wrap;
  }
  .btns button {
    margin-right: 4vw;
  }
  .btns button:last-child {
    margin-right: 0px;
  }
  .btns.w100 {
    padding: 0 0 6vw;
  }
  .btns.w100 button {
    margin-right: 4vw;
    padding: .5em 1em;
  }
  .btns.w100 p {
    font-size: 3.6vw;
    padding: .5em 0 0;
  }
  .btns.w100 p span {
    padding-right: 1em;
  }
  .chart {
    padding: 4vw 0 6vw;
  }
  .chart .live {
    background: rgb(255,255,255);
  }
  .chart .btns label {
    font-size: 3.4vw;
    padding-right: 1em;
    cursor: pointer;
    transition: .3s;
  }
  .chart .btns label:hover {
    color: rgb(70, 150, 255);
  }
  .chart .average-value {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    font-size: 3vw;
    padding: .5em 1em;
  }
  .note {
    padding: 0 0 8vw;
  }
  .note h3 {
    font-size: 3.8vw;
    border-top: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    margin: 0 0 .5em;
    padding: .5em;
  }
  .note ul {
    font-size: 3.4vw;
  }
  .note ul li {
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-bottom: rgb(200,200,200) dashed 1px;
  }
  .note ul li p em {
    font-style: normal;
    padding-left: 1em;
  }
  .memo-ipt {
    font-size: 3.4vw;
  }
  .memo-ipt p {
    padding-bottom: .5em;
  }
  .note .memo-ipt input {
    font-size: 3.4vw;
    display: block;
    width: 100%;
    margin-bottom: .5em;
    box-sizing: border-box;
  }
  .memo-ipt p button {
    display: inline-block;
    margin-left: 1em;
  }
}
@media all and (min-width: 480px) {
  .chart .live {
    position: relative;
  }
  .chart .live .live-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, .8);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .chart-container {
    padding: 4em;
    position: relative;
  }
  .velmld-parent {
    position: fixed!important;
  }
  .info-notes {
    padding-bottom: 4vw;
  }
  .info-notes p {
    font-size: 1vw;
  }
  .cont h2 {
    font-size: 1.4vw;
    padding-bottom: 1em;
  }
  .btns {
    display: flex;
    flex-flow: row wrap;
  }
  .btns button {
    margin-right: 1.5vw;
  }
  .btns button:last-child {
    margin-right: 0px;
  }
  .btns.w100 {
    padding: 0 0 2vw;
  }
  .btns.w100 p {
    font-size: 1.2vw;
    padding: .4em 0;
  }
  .btns.w100 p span {
    padding-right: 1em;
  }
  .chart {
    padding: 2vw 0;
  }
  .chart .live {
    background: rgb(255,255,255);
  }
  .chart .btns label {
    font-size: 1.2vw;
    padding-right: 1em;
    cursor: pointer;
    transition: .3s;
  }
  .chart .btns label:hover {
    color: rgb(70, 150, 255);
  }
  .chart .average-value {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    font-size: 1vw;
    padding: .5em 1em;
  }
  .note {
    padding: 0 0 30px;
  }
  .note h3 {
    font-size: 1.1vw;
    border-top: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    margin: 0 0 .5em;
    padding: .5em;
  }
  .note ul {
    font-size: 1vw;
  }
  .note ul li {
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-bottom: rgb(200,200,200) dashed 1px;
  }
  .note ul li p em {
    font-style: normal;
    padding-left: 1em;
  }
  .memo-ipt {
    font-size: 1vw;
  }
  .memo-ipt p {
    padding-bottom: .5em;
  }
  .note .memo-ipt input {
    font-size: 1vw;
    display: block;
    width: 100%;
    margin-bottom: .5em;
  }
  .memo-ipt p button {
    display: inline-block;
    margin-left: 1em;
  }
}
</style>
